@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@layer base {
  :root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: theme(colors.green.800);
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: theme(colors.red.800);
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: theme(colors.green.800);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: theme(colors.red.800);

    --toastify-toast-width: 320px;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 70px;
    --toastify-toast-max-height: 800px;
    --toastify-z-index: 9999;
    --toastify-font-family: theme(fontFamily.poppins);

    --toastify-text-color-light: #757575;
    --toastify-text-color-dark: #fff;

    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;
    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;
  }
  .Toastify__toast-icon {
    @apply [&_svg]:!w-7 !important;
  }
  body {
    @apply font-poppins bg-white text-primary-grayDark;
  }
  tbody {
    /* display: block; */
    overflow: auto;
    min-height: 500px;
  }
  table {
    width: 100%;
  }
  svg {
    color: inherit;
    font-size: inherit;
  }
  /* thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  
  } */
  th {
    @apply whitespace-nowrap  py-6 px-2  text-sm md:text-base   font-semibold first:text-left first:pl-4 text-white bg-primary-pink last:rounded-tr-[8px] first:rounded-tl-[8px];
  }

  td {
    @apply align-middle text-xs md:text-sm py-6 px-2   first:text-left first:pl-4 md:whitespace-normal whitespace-nowrap;
  }
 
  tr {
    @apply border-b hover:bg-gray-200  text-center;
  }
  .user-layout tr {
    @apply border-b-dark hover:bg-primary-darkBlueSupport;
  }
  .user-layout .icon-color svg{
    @apply text-white fill-white;
  }
}
@layer components {
  .smooth-scroll {
    scroll-behavior: smooth;
    transition: transform 0.3s ease-out;
  }
  .header-container-wrapper {
    @apply relative z-[4] md:min-h-[500px] min-h-[600px] lg:min-h-[700px] flex justify-center items-center text-center;
  }
  .form-error {
    @apply text-sm text-red-500;
  }
  .heading-2 {
    @apply lg:text-7xl md:text-5xl text-4xl leading-[1.3]  lg:leading-[1.15];
  }
  .heading-3 {
    @apply text-primary-grayDark md:text-5xl text-3xl leading-[1.3] lg:leading-[1.15] font-semibold;
  }
  .heading-4 {
    @apply lg:text-2xl text-xl font-semibold;
  }
  .heading-5 {
    @apply text-xl font-semibold;
  }
  .heading-6 {
    @apply text-lg font-semibold;
  }
  .heading-7 {
    @apply text-[13px] font-semibold;
  }
  .flex-center {
    @apply flex justify-center items-center;
  }
  .ay-center {
    @apply absolute top-1/2 -translate-y-1/2;
  }
  .ax-center {
    @apply absolute left-1/2 -translate-x-1/2;
  }
  .a-center {
    @apply absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2;
  }
  .btn {
    @apply min-w-[140px] lg:px-10 px-6 py-2 font-semibold  text-[12px] md:text-base rounded-md disabled:opacity-60 disabled:cursor-not-allowed;
  }
  .btn-sm {
    @apply text-sm px-6 font-medium py-2;
  }
  .btn-green {
    @apply btn bg-primary-green text-white;
  }
  .btn-primary {
    @apply btn bg-primary-pink hover:bg-primary-pink/80 text-white;
  }
  .btn-outline-primary {
    @apply btn text-primary-pink border border-primary-pink hover:bg-primary-pink hover:text-white;
  }
  .btn-outline-white {
    @apply btn text-white border border-white hover:bg-white hover:text-primary-pink;
  }
  .btn-secondary {
    @apply btn bg-primary-btnColor text-white;
  }
  .btn-red {
    @apply btn bg-primary-red text-white;
  }
  .btn-sm {
    @apply text-sm px-6 font-medium py-2;
  }
  .text-muted {
    @apply text-primary-gray text-sm md:text-base;
  }
  .action-btn {
    @apply w-10 h-10 cursor-pointer rounded-full text-xl text-primary-blue bg-zinc-200 hover:bg-zinc-300  flex-center;
  }
  .action-sm {
    @apply w-8 h-8;
  }
  .hoverable-img {
    @apply hover:scale-110 duration-200 object-cover w-full h-full;
  }
  .input-field {
    @apply w-full py-2 px-4 rounded-md border  focus:outline-primary-pink text-black text-base;
  }
  .pagination-item-style {
    @apply flex justify-center items-center  border rounded-md border-primary-pink text-primary-pink cursor-pointer hover:bg-primary-pink hover:text-white;
  }

  .disabled-item {
    @apply opacity-50   hover:text-white;
  }
  .pagination-item-style a,
  .disabled-item a {
    @apply w-full h-full flex-center md:text-base text-sm px-4 py-1;
  }
  .skeleton {
    @apply bg-zinc-300 animate-pulse;
  }
  .form-error {
    @apply text-red-600 text-sm;
  }
  .border-c {
    @apply border border-zinc-300;
  }
  .border-dark {
    @apply border border-blue-950;
  }
  .border-b-dark {
    @apply border-b border-b-blue-950;
  }
  /* .header-wrapper{
    @apply pt-[96px];
  } */
  .shaodw-price {
    box-shadow: 0 1px 20px 0 rgba(17, 30, 79, 0.07);
  }
  .shadow-num {
    box-shadow: 0 1px 20px 0 rgba(17, 30, 79, 0.1) !important;
  }
  .tradingview-widget-copyright {
    display: none !important;
  }
}
